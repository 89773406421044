import React, { Component } from "react";
import styles from "./Header.module.scss";
import menuImg from "../../assets/icon_bars_light.svg";
import { ReactComponent as Share } from "../../assets/share.svg";
import logoImg from "../../assets/YST-LOGO_MASTER_BLUE_DARK_GREY_32.png";
import DOMPurify from "dompurify";
import { ThemeContext } from "../../../Context/ThemeContext";
import { Context as LanguageContext } from "../../../Context/LanguageContext";
import { isMobile } from "react-device-detect";
import safeLocalStorageService from "../../../services/useSafeLocalStorage";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
    };
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  _renderContent = (item, themeContext) => {
    const { width } = this.state;

    return (
      <>
        {" "}
        {item.url !== undefined ? (
          <a
            href={item.url}
            className={styles["link-text-custom"]}
            style={{
              color:
                themeContext?.header?.textColour !== undefined
                  ? themeContext?.header?.textColour
                  : "#484848",
              "--header--hover-color":
                themeContext?.header?.textHoverColour !== undefined
                  ? themeContext?.header?.textHoverColour
                  : "#008489",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item.text, {
                  ADD_ATTR: ["target"],
                  // sanitize: true, TODO: Re-implement this!
                }),
              }}
            />
          </a>
        ) : (
          <span className={styles["header-text"]}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item.text, {
                  ADD_ATTR: ["target"],
                  // sanitize: true, TODO: Re-implement this!
                }),
              }}
            />
          </span>
        )}
      </>
    );
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  renderLanguageSelector = (theme) => {
    return (
      <LanguageContext.Consumer>
        {({ locale, selectLanguage }) => {
          const languages = theme?.header?.languages || [];

          if (languages.length === 0) {
            return null;
          }
          return (
            <div className={styles["language-selector-container"]}>
              {languages.map((lang) => (
                <span
                  key={lang.code}
                  className={`${styles["language-selector"]} ${
                    lang.code === locale
                      ? styles["language-selector-active"]
                      : ""
                  }`}
                  style={{
                    color:
                      lang.code === locale
                        ? theme?.header?.textHoverColour || theme?.hoverColour
                        : theme?.header?.textColour || theme?.primaryColour,
                  }}
                  onClick={() =>
                    this.handleLanguageChange(lang.code, selectLanguage)
                  }
                >
                  {lang.name}
                </span>
              ))}
            </div>
          );
        }}
      </LanguageContext.Consumer>
    );
  };

  handleLanguageChange = (langCode, selectLanguage) => {
    selectLanguage(langCode);

    safeLocalStorageService.setItem("lang", langCode);
  };

  render() {
    const { showMenu } = this.props;
    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const themeContext = theme?.theme;

          return (
            <div
              className={styles["Header"]}
              style={{
                color: isMobile
                  ? themeContext?.header?.mobileTextColour !== undefined
                    ? themeContext?.header?.mobileTextColour
                    : "#484848"
                  : themeContext?.header?.textColour !== undefined
                  ? themeContext?.header?.textColour
                  : "#484848",
                background: isMobile
                  ? themeContext?.header?.mobileBackgroundColour !== undefined
                    ? themeContext?.header?.mobileBackgroundColour
                    : "#ffffff"
                  : themeContext?.header?.backgroundColour !== undefined
                  ? themeContext?.header?.backgroundColour
                  : "#ffffff",
                "--hover-color": isMobile
                  ? themeContext?.header?.mobileTextHoverColour !== undefined
                    ? themeContext?.header?.mobileTextHoverColour
                    : "#ffffff"
                  : themeContext?.header?.textHoverColour !== undefined
                  ? themeContext?.header?.textHoverColour
                  : "#008489",
              }}
            >
              {isMobile ? (
                <div className={styles["header-outer-container-mobile"]}>
                  <div className={styles["header-inner-container"]}>
                    <div
                      className={styles["header-inner-left"]}
                      style={{
                        width: "80px",
                        flex: "0 0 80px",
                      }}
                    >
                      <div
                        style={{
                          float: "left",
                          paddingLeft: "10px",
                        }}
                        className={styles["menu-button-container"]}
                        onClick={showMenu}
                      >
                        <img
                          src={menuImg}
                          className={styles["menu-button"]}
                          alt="Menu icon opens travel profile"
                        />{" "}
                      </div>
                    </div>
                    <div
                      className={styles["header-inner-center"]}
                      style={{
                        flex: "1 1 auto",
                        position: "relative",
                        textAlign: "center",
                      }}
                    >
                      <img
                        height={33}
                        width={33}
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          maxWidth: "100%",
                          objectFit: "contain",
                        }}
                        src={themeContext?.desktopHeader?.imageUrl}
                        alt="Logo"
                      />
                    </div>

                    <div
                      className={styles["header-inner-right"]}
                      style={{
                        width: "80px",
                        flex: "0 0 80px",
                        textAlign: "right",
                      }}
                    >
                      {this.renderLanguageSelector(themeContext)}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles["header-outer-container"]}>
                  <div className={styles["header-inner-container"]}>
                    <div className={styles["header-inner-left"]}>
                      {themeContext?.desktopHeader?.items?.[0] !== undefined &&
                        this._renderContent(
                          themeContext.desktopHeader.items[0],
                          themeContext
                        )}
                    </div>
                    <div className={styles["header-inner-center"]}>
                      {themeContext?.desktopHeader?.items?.[1] !== undefined &&
                        this._renderContent(
                          themeContext.desktopHeader.items[1],
                          themeContext
                        )}
                    </div>
                    <div className={styles["header-inner-right"]}>
                      {this.renderLanguageSelector(themeContext)}
                    </div>{" "}
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

export default Header;
