import React, { Component } from "react";
import styles from "./Confirmation.module.scss";
import { FormattedMessage } from "react-intl";

class Confirmation extends Component {
  render() {
    const title = this.props.ConfirmedAssistance ? (
      <FormattedMessage id="assistanceConfirmed" />
    ) : (
      <FormattedMessage id="congratulations" />
    );
    const message = this.props.ConfirmedAssistance ? (
      <FormattedMessage id="confirmingAssistance" />
    ) : this.props.isAssistanceConfirmation ? (
      <FormattedMessage id="updateNominated" />
    ) : (
      <FormattedMessage id="updateYouWithInformation" />
    );
    const linkText = <FormattedMessage id="planAnotherTrip" />;
    return (
      <div className={styles["header-outer-container"]}>
        <div
          role="button"
          tabIndex="0"
          aria-pressed="false"
          className={styles["Confirmation"]}
        >
          <div className={styles["outer-container"]}>
            <div className={styles["inner-container"]}>
              <div className={styles["no-margin"]}>
                <span className={styles["span"]} />
                <div className={styles["section-outer-container"]}>
                  <div className={styles["section-inner-container"]}>
                    <div className={styles["prepend-container"]}>
                      <div className={styles["prepend"]}> </div>
                    </div>
                    <section
                      aria-label={title}
                      tabIndex="0"
                    >
                      <h2
                        tabIndex="-1"
                        className={styles["h2"]}
                      >
                        <div className={styles["header-content"]}>
                          <div className={styles["header"]}>
                            <span aria-label={title}>{title}</span>
                          </div>
                        </div>
                      </h2>
                    </section>
                    <div className={styles["pre-outer-container"]}>
                      <div className={styles["pre-mobile-container"]}>
                        <div className={styles["pre-inner-container"]}>
                          <span
                            aria-label={message}
                            tabIndex="0"
                          >
                            <pre className={styles["pre"]}>{message}</pre>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={styles["pre-outer-container"]}>
                      <div className={styles["pre-mobile-container"]}>
                        <div className={styles["pre-inner-container"]}>
                          <pre className={styles["pre"]}>
                            {this.props.ConfirmedAssistance ? (
                              <a
                                href={window.location.origin}
                                rel="noopener noreferrer"
                                className={styles["a"]}
                              >
                                <span aria-label={linkText}>{linkText}</span>
                              </a>
                            ) : (
                              <a
                                href={
                                  window.location.origin +
                                  this.props.destination
                                }
                                rel="noopener noreferrer"
                                className={styles["a"]}
                              >
                                <span aria-label={linkText}>{linkText}</span>
                              </a>
                            )}
                          </pre>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Confirmation;
